import React, { useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../../../../constants/localizations";
import SectionHeader from "../../../../orders/single-view/parts/SectionHeader";
import SummaryEditor from "../../parts/SummaryEditor";
import placeholder from "../../../../../assets/images/dashboard/placeholderPNG.png";
import Viewer from "../../../../buildings/single-view/view/Viewer";
import PackageExportEdit from "../edit/PackageExportEdit";

const PackageInformation = (props) => {
	const {
		isForPublicView = false,
		name,
		modelLink,
		modelFileUrn,
		summary,
		previewLink,
	} = props;

	const renderModelContent = useMemo(() => {
		if (modelFileUrn) {
			return <Viewer isForPackage={true} viewerUrn={modelFileUrn} />;
		} else if (modelLink) {
			return (
				<div className="pos-rel h-100 w-100">
					<iframe
						title={name}
						src={modelLink}
						frameBorder="0"
						width={"100%"}
						height={"100%"}
						webkitallowfullscreen="true"
						mozallowfullscreen="true"
						allowFullScreen
						className="border-t-1 border-n200"
					></iframe>

					<div className="pos-abs w-100 bg-n000 top-0 left-0 h-50p"></div>
				</div>
			);
		} else {
			return (
				<LazyLoadImage
					className="h-100 w-100 border-1 border-n150 border-radius-regular bg-n100 package-image-resizer"
					alt={name}
					key={previewLink || placeholder}
					src={previewLink || placeholder}
					placeholderSrc={placeholder}
					effect="blur"
					threshold={100}
					wrapperClassName="w-100 h-350p"
				/>
			);
		}
	}, [modelLink, name, previewLink, modelFileUrn]);

	const modelClassName = useMemo(() => {
		if (modelFileUrn) {
			return "border-1 border-n150 border-radius-regular bg-n000";
		}
		return modelLink ? "h-400p mt-neg-50" : "";
	}, [modelLink, modelFileUrn]);

	return (
		<>
			<div className="row row--m-gutters">
				<div className="col col-desktop-50 col-100">
					<div className={`h-350p w-100 mb-32 ${modelClassName} `}>
						{renderModelContent}
					</div>
				</div>
				<div className="col col-desktop-50 col-100">
					<div className="d-flex flex-column w-100">
						<div className="d-flex align-items-center mb-regular">
							<SectionHeader title={localize(LocKeys.SUMMARY_LABEL)} />
						</div>
						<div className="pb-32">
							<SummaryEditor
								placeholder={localize(LocKeys.ENTER_SHORT_DESCRIPTION).concat(
									"..."
								)}
								manual={summary}
								editable={false}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="row row--m-gutters mb-32">
				<div className="col col-100">
					<div className="d-flex flex-column w-100">
						<div className="row row--6p-gutters  align-items-center pb-regular border-b-1 border-n150">
							<div className="w-100 d-flex">
								<SectionHeader title={localize(LocKeys.EXPORTS)} />
							</div>
						</div>

						{/* {exports && 
									exports.size > 0 &&
									exports.map((itemExport, i) => {
										return (
											<div key={i} className="d-flex align-items-start mb-s">
												<IconWrapper
													icon={getExportImage(
														itemExport?.getIn(["export", "type"])
													)}
													size={24}
													wrapperClasses="mr-xs"
												/>
												<div>{itemExport?.getIn(["export", "type"])}</div>
												<div className="color-n300">
													&nbsp; (+ {itemExport?.getIn(["export", "price"])}
													&nbsp; &euro;/
													{localize(LocKeys.BUILDING)?.toLowerCase()})
												</div>
											</div>
										);
									})} */}

						<PackageExportEdit isForPublicView={isForPublicView} />
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		name: state.getIn(["dashboard", "salesPackage", "name"]),
		previewLink: state.getIn(["dashboard", "salesPackage", "previewLink"]),
		summary: state.getIn(["dashboard", "salesPackage", "summary"]),
		modelLink: state.getIn([
			"dashboard",
			"salesPackage",
			"threeHundredSixtyUrl",
		]),
		modelFileUrn: state.getIn([
			"dashboard",
			"salesPackage",
			"revitModel",
			"forgeUrnPreviewLink",
		]),
	};
};

export default connect(mapStateToProps, {})(PackageInformation);
