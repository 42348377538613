import React, { useEffect, useMemo, useState } from "react";
import ComponentCategoryRow from "./ComponentCategoryRow";
import localize, { LocKeys } from "../../../constants/localizations";
import { connect } from "react-redux";
import { Typography } from "@mui/material";
import CustomSkeleton from "../../components/skeleton/CustomSkeleton";

const SpecificationDisciplineContent = ({
	disciplineId,
	disciplineComponentCategories,
	jobView,
	isForPackage,
	editable,
	editView = false,
	updateSelected,
	initialSelected,
	lods,
}) => {
	const skeletonItems = new Array(7).fill(0);
	const [selected, setSelected] = useState(
		initialSelected ? initialSelected : []
	);

	useEffect(() => {
		setSelected(initialSelected ? initialSelected : []);
	}, [initialSelected]);

	const toggleSelected = (data) => {
		updateSelected && updateSelected(data);
	};

	const lodlasses = useMemo(() => {
		return isForPackage ? "" : "px-24";
	}, [isForPackage]);

	const diciplineContentClasses = useMemo(() => {
		if (isForPackage) {
			return "";
		}

		return `px-24 overflow-x-hidden overflow-y-auto ${jobView ? "page-content--517" : "page-content--403"}`;
	}, [jobView, isForPackage]);

	return (
		<div className={`discipline__wrapper`}>
			{/* <div className="py-regular w-100">
				<div className="row row--no-gutters w-100">
					<div className="col col-15 pl-0 pr-0"></div>
					{lods &&
						lods.map((value, i) => {
							return (
								<div
									key={`lod-head-` + value.get("id")}
									className={`col  ${i === 0 ? "col-5" : "col-16"} pl-0 pr-0`}
								>
									<h6>
										{value.get("type") === "0"
											? localize(LocKeys.NONE)
											: `${localize(LocKeys.LOD)} ${value.get("type")}`}
									</h6>
								</div>
							);
						})}
				</div>
			</div> */}
			<div
				className={`lod py-regular h-50p border-b-1 border-n150 w-100 ${lodlasses}`}
			>
				<div className="d-flex w-100 pr-42">
					<div className="col col-85  d-flex justify-content-between mr-0 ml-auto">
						{lods &&
							lods.map((value) => {
								return (
									<div
										key={`lod-head-` + value.get("id")}
										className="lod__value"
									>
										<h6>
											{value.get("type") === "0"
												? localize(LocKeys.NONE)
												: `${localize(LocKeys.LOD)} ${value.get("type")}`}
										</h6>
									</div>
								);
							})}
					</div>
				</div>
			</div>
			<div
				className={`dicipline-content__wrapper pb-24 ${diciplineContentClasses}`}
			>
				{disciplineComponentCategories && selected?.length > 1
					? disciplineComponentCategories.map(
							(disciplineComponentCategory, index) => {
								const componentCategory =
									disciplineComponentCategory.componentCategory;

								const selectedLodValue =
									selected &&
									selected.find((el) => {
										return (
											el.componentCategoryId === componentCategory.id &&
											el.disciplineId === disciplineId
										);
									});

								const categoryExist = selected.find(
									(el) =>
										el.componentCategoryId === componentCategory.id &&
										el.disciplineId === disciplineId
								);

								if (!categoryExist) {
									return "";
								}

								return (
									<div className="border-b-1 border-n150">
										<ComponentCategoryRow
											key={index}
											editView={editView}
											isForPackage={isForPackage}
											id={disciplineComponentCategory?.id}
											componentCategory={componentCategory}
											selectedLodValue={selectedLodValue}
											editable={editable}
											handleChange={(
												componentCategoryId,
												lodValueId,
												lodValueType
											) =>
												toggleSelected({
													componentCategoryId,
													lodValueId,
													lodValueType,
													disciplineId,
												})
											}
										/>
									</div>
								);
							}
						)
					: skeletonItems.map((_, index) => {
							return (
								<div key={index} className="border-b-1 border-n150">
									<div className="d-flex w-100 row row--6p-gutters align-items-center py-s">
										<div className="col col-100">
											<Typography style={{ width: "100%" }} variant="h4">
												<CustomSkeleton />
											</Typography>
										</div>
									</div>
								</div>
							);
						})}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		lods: state.getIn(["administration", "lods"]),
	};
};

export default connect(mapStateToProps, {})(SpecificationDisciplineContent);
